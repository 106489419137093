/**页面常用的公用的方法混入 */
export default {
    data() {
        return {
            total: 0, //  总条数
        }
    },
    methods: {
        /**
         * 设置表格序号
         */
        indexMethod(index) {
            let {
                current,
                size
            } = this.pageParams;
            return (current - 1) * size + index + 1;
        },
        /**
         * 每页显示条数变更
         */
        handleSizeChange(val) {
            this.pageParams.size = val;
            this.handleCurrentChange(1);
        },
        /**
         * 页签变更
         */
        handleCurrentChange(val) {
            this.pageParams.current = val;
            this.loadList();
        },
    }
}